// if tab key is used, add class to show outline styles
function handleFirstTab(e) {
  if (e.keyCode === 9) {
    document.body.classList.add('user-tabbing');
    window.removeEventListener('keydown', handleFirstTab);
  }
}

export default function() {
  window.addEventListener('keydown', handleFirstTab);
}