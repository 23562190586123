import { gsap } from 'gsap';
/* The following plugin is a Club GSAP perk */
import { SplitText } from 'gsap/SplitText';
gsap.registerPlugin(SplitText);

export default function() {
  const text = document.querySelectorAll('.js-anim-text');
  const split = new SplitText(text, {
    type: 'words, lines',
    linesClass: 'anim-text-line',
  });
  const words = split.words;
  // Animate each character into place from 100px above, fading in:
  const tl = gsap.timeline();
  tl.set(text, {opacity: 1});
  gsap.from(words, {
    duration: 0.5, 
    yPercent: 100,
    stagger: 0.025,
    ease: "power2.out",
  });
}