import Player from '@vimeo/player';

const videos = Array.from(document.querySelectorAll('.js-video'));
let currentVideoPlaying = {};

function newYouTubePlayer(video) {
  video.classList.add('video-is-clicked');
  const figure = video.querySelector('.js-video-figure');
  const elId = figure.id;
  // const elId = container.id;
  // Define onPlayerReady function in here so it's scoped to the player
  function onPlayerReady(event) {
    event.target.playVideo();
  }
  // Build videoId and initialisation options
  let start = 0;
  let videoId = null;
  const url = video.href;
  // Get querystring portion of URL
  const qs = url.split('?')[1];
  // Split querystring into params
  const params = new URLSearchParams(qs);
  start = params.get('t');
  videoId = params.get('v');
  // Youtube replaces whatever DOM element you pass in, so append an empty container div
  const container = document.createElement('div');
  figure.prepend(container);
  // Construct the new player
  const player = new YT.Player(container, {
    origin: window.location.origin,
    height: video.offsetHeight,
    width: video.offsetWidth,
    videoId: videoId,
    playerVars: {
      start: start
    },
    events: {
      'onReady': onPlayerReady,
      'onStateChange': function(event) {
        if (event.data === 1 && currentVideoPlaying) {
          pauseCurrentPlayNew({
            newId: elId,
            newPlayer: player,
            newType: 'youtube'
          });
        }
      }
    }
  });
}

function initYouTubeIframeAPI(video) {
  window.onYouTubeIframeAPIReady = function() {
    newYouTubePlayer(video);
  }
}

function isYouTube(url) {
  return url.includes('watch?v=') || url.includes('.be/');
}

function isVimeo(url) {
  return url.includes('vimeo');
}

function initYouTubeVideo(video) {
  // Check youtube URL format
  const videoInitialised = video.dataset.init;
  if (videoInitialised === 'true') {
    return;
  } else {
    video.dataset.init = 'true';
  }
  // Check if youtube script is already on the page
  if (window.YT) {
    newYouTubePlayer(video);
    return;
  }
  // If not, add it..
  initYouTubeIframeAPI(video);
  // Load the IFrame Player API code asynchronously.
  const tag = document.createElement('script');
  tag.src = "https://www.youtube.com/iframe_api";
  tag.id = 'youtube-script';
  const firstScriptTag = document.getElementsByTagName('script')[0];
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  // This triggers the global onYouTubeIframeAPIReady
}

function initVimeoVideo(video) {
  const url = video.href;
  const elId = video.querySelector('.js-video-figure').id;
  const width = video.offsetWidth;
  const player = new Player(elId,  {
    url: url,
    width: width,
  });
  player.ready().then(function() {
    video.classList.add('video-is-clicked');
    player.play();
  });

  player.on('play', function() {
    pauseCurrentPlayNew({
      newId: elId,
      newPlayer: player,
      newType: 'vimeo'
    });
  });
}

function pauseCurrentPlayNew(params) {
  const { newId, newPlayer, newType } = params;
  const { currentPlayer, currentType, currentId } = currentVideoPlaying;
  if (currentId !== newId) {
    if (currentType === 'vimeo') {
      currentPlayer.pause();
    } else if (currentType === 'youtube') {
      currentPlayer.pauseVideo();
    }

    currentVideoPlaying = {
      currentId: newId,
      currentPlayer: newPlayer,
      currentType: newType
    }
  }
}

function checkVideoType(video) {
  const url = video.href;
  if (isYouTube(url)) {
    initYouTubeVideo(video);
  } else if (isVimeo(url)) {
    initVimeoVideo(video);
  }
}

function handleClick(e) {
  const clicked = e.target;
  const video = clicked.closest('.js-video');
  if (!video) {
    return;
  }
  e.preventDefault();
  checkVideoType(video);
}

export default function() {
  videos.forEach(video => video.addEventListener('click', handleClick));
}
