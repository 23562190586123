import { gsap } from "gsap";    
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);

const skip = document.getElementById('skip-to-content');
const header = document.getElementById('header');

function handleClick(e) {
  e.preventDefault();
  const link = e.target.closest('a');
  const selector = link.getAttribute('href');
  const match = document.querySelector(selector);
  const offsetY = header.offsetHeight - gsap.getProperty(match.firstElementChild, 'padding-top');

  gsap.to(window, {
    duration: 0.2,
    scrollTo: {
      y: match,
      offsetY: offsetY
    },
    onComplete() {
      match.focus({preventScroll: true});
    }
  });
}

export default function() {
  skip.addEventListener('click', handleClick);
}